import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./index.css";
import {
  Color,
  newPhiPalette,
  randomColor,
  toHsl,
  allPaletteColors,
  turnColor,
  lightenColor,
  saturateColor,
} from "../libs/color-pallete";
import { genRange } from "../libs/rand";

const AnimatedTitle = () => {
  const [header, setHeader] = useState("▖▌▌▘▌▌".repeat(10));
  useEffect(() => {
    const tick = setInterval(() => {
      setHeader(header.slice(1).concat(header[0]));
    }, 500);
    return () => clearInterval(tick);
  }, [header]);
  return (
    <Helmet>
      <title>{header}</title>
    </Helmet>
  );
};

interface LogoProps {
  colors: Color[];
}

const Logo = ({ colors }: LogoProps) => {
  return (
    <div className="logo">
      <div
        className="logo_j_short"
        style={{ backgroundColor: toHsl(colors[0]) }}
      />
      <div
        className="logo_j_tall"
        style={{ backgroundColor: toHsl(colors[1]) }}
      />
      <div
        className="logo_p_tall"
        style={{ backgroundColor: toHsl(colors[2]) }}
      />
      <div
        className="logo_p_short"
        style={{ backgroundColor: toHsl(colors[3]) }}
      />
    </div>
  );
};

// markup
const IndexPage = () => {
  const [baseColors, setColors] = useState([
    randomColor({ minSat: 0.6, maxSat: 0.9, minLit: 0.6, maxLit: 0.8 }),
    randomColor({ minSat: 0.6, maxSat: 0.9, minLit: 0.6, maxLit: 0.8 }),
  ]);

  useEffect(() => {
    const tick = setInterval(() => {
      setColors(
        baseColors.map((color) => turnColor(color, genRange(-0.5, 0.5)))
      );
    }, 2000);
    return () => clearInterval(tick);
  }, [baseColors]);

  const colors = allPaletteColors(newPhiPalette(baseColors[0], baseColors[1]));

  return (
    <main>
      <AnimatedTitle />
      <div
        className="header"
        style={{
          backgroundColor: toHsl(
            saturateColor(lightenColor(colors[0], 0.2), -0.3)
          ),
        }}
      >
        <Logo colors={colors.slice(2)} />
        <div className="info">
          <p>
            hello 👋 my name is julian. i'm an artist / applied crypto developer
            living in paris. feel free to{" "}
            <a
              href="mailto:hello@julian.dev"
              style={{ color: toHsl(lightenColor(colors[1], -0.3)) }}
            >
              send me emails
            </a>
            ,{" "}
            <a
              href="https://twitter.com/jpopesculian"
              style={{ color: toHsl(lightenColor(colors[3], -0.3)) }}
            >
              tweet at me
            </a>
            ,{" "}
            <a
              href="https://github.com/jpopesculian"
              style={{ color: toHsl(lightenColor(colors[5], -0.3)) }}
            >
              send a pull request
            </a>{" "}
            or connect whichever way you feel comfortable with
          </p>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
