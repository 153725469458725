import { genRange } from "./rand";

export interface Color {
  hue: number;
  sat: number;
  lit: number;
}

const normalize = ({ hue, sat, lit }: Color): Color => {
  if (hue > 1) {
    hue %= 1;
  } else if (hue < 0) {
    hue = (hue % 1) + 1;
  }
  return {
    hue,
    sat: Math.min(Math.max(sat, 0), 1),
    lit: Math.min(Math.max(lit, 0), 1),
  };
};

export const invertColor = ({ hue, sat, lit }: Color): Color => {
  return { hue: 1 - hue, sat, lit };
};

export const turnColor = ({ hue, sat, lit }: Color, amt: number): Color => {
  return normalize({ hue: hue + amt, sat, lit });
};

export const lightenColor = ({ hue, sat, lit }: Color, amt: number): Color => {
  return normalize({ hue, sat, lit: lit + amt });
};

export const saturateColor = ({ hue, sat, lit }: Color, amt: number): Color => {
  return normalize({ hue, sat: sat + amt, lit });
};

export const toHsl = ({ hue, sat, lit }: Color): string => {
  return `hsl(${Math.round(hue * 360)}, ${Math.round(sat * 100)}%, ${Math.round(
    lit * 100
  )}%)`;
};

export interface RandomColorOptions {
  minHue?: number;
  maxHue?: number;
  minSat?: number;
  maxSat?: number;
  minLit?: number;
  maxLit?: number;
}

export const randomColor = ({
  minHue,
  maxHue,
  minSat,
  maxSat,
  minLit,
  maxLit,
}: RandomColorOptions = {}): Color => {
  return normalize({
    hue: genRange(minHue, maxHue),
    sat: genRange(minSat, maxSat),
    lit: genRange(minLit, maxLit),
  });
};

export interface PhiPalette {
  color1: Color;
  color2: Color;
  intervals: Color[];
}

const PHI_RATIOS = [0.382, 0.618, 1.618, 2.618];

const calcPhiIntervals = (
  { hue: hue1, sat, lit }: Color,
  { hue: hue2 }: Color
) => {
  const diff = hue2 - hue1;
  return PHI_RATIOS.map((ratio) =>
    normalize({
      hue: hue1 + diff * ratio,
      sat,
      lit,
    })
  );
};

export const newPhiPalette = (
  { hue: hue1, sat: sat1, lit: lit1 }: Color,
  { hue: hue2, sat: sat2, lit: lit2 }: Color
): PhiPalette => {
  const avgSat = (sat1 + sat2) / 2;
  const avgLit = (lit1 + lit2) / 2;
  const color1 = { hue: hue1, sat: avgSat, lit: avgLit };
  const color2 = { hue: hue2, sat: avgSat, lit: avgLit };
  return { color1, color2, intervals: calcPhiIntervals(color1, color2) };
};

export const allPaletteColors = ({
  color1,
  color2,
  intervals,
}: PhiPalette): Color[] => {
  return [color1, color2].concat(intervals);
};
